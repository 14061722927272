import React, {useEffect, useState} from "react";
import {Card, Carousel} from "react-bootstrap";

export default function SponsorSlide(props) {
    const [sponsors, setSponsors] = useState(null);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/sponsor`)
            .then(data => data.json())
            .then(data => shuffle(data))
            .then(setSponsors);
    }, []);

    if(sponsors == null) {
        return null;
    }

    const items = sponsors.map((sponsor, i) =>
        <Carousel.Item key={i} interval={3000}>
            <SponsorItem item={sponsor}/>
        </Carousel.Item>
    );

    return (
        <>
            <Card className={'mb-2 mt-2'} style={{backgroundColor:'#fff', opacity: 1}}>
                <Carousel variant='dark' controls={false} indicators={false}>
                    {items}
                </Carousel>
            </Card>
        </>
    )
}

function SponsorItem({item}) {
    const logoUrl = `${process.env.REACT_APP_API_URL}/sponsor/${item.id}/logo`;
    return (
        <div style={{height: '300px'}}>
            <a href={item.website} target={"_blank"} rel="noopener noreferrer">
                <div className={'d-flex justify-content-around '}>
                    <Carousel.Caption>
                        {!!item.hasLogo && <div className={'p-2'}>
                            <img alt={item.subtitle} style={{maxHeight: '200px', maxWidth: '500px'}} src={logoUrl} />
                        </div>
                        }
                        {!!!item.hasLogo && <h3 className={'text-black-50'}>{item.name}</h3>}
                        {!!item.subtitle && <p className={'text-black-50'}>{item.subtitle}</p>}
                    </Carousel.Caption>
                </div>
            </a>
        </div>
    )
}

function shuffle(array) {
    const newArray = [...array]
    const length = newArray.length

    for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random())
        const randomItem = newArray.splice(randomPosition, 1)

        newArray.push(...randomItem)
    }

    return newArray
}
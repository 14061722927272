import React, {useState} from "react";
import {Alert, Button, Card, Form} from "react-bootstrap";
import {useLogin} from "../context/login.context";
import {useNavigate} from "react-router-dom";


export default function Login() {
    const {loggedIn, login} = useLogin();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    if (loggedIn.loggedIn) {
        navigate('/admin');
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const user = formData.get("username")
        const password = formData.get("password")

        // fetch(`${process.env.REACT_APP_API_URL}/login`, {
        //     method: 'POST', // or 'PUT'
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({username: user, password: password}),
        // })
        // .then(r => r.json())
        // .then(loggedin => {
        //     if(loggedin) {
        //         login()
        //         navigate('/admin');
        //     } else {
        //         setError(true);
        //     }
        // })

        if(user === 'strooteamfm' && password === 'lekkerzenden') {
            login()
            navigate('/admin');
        } else {
            setError(true);
        }

        return false;
    }

    return (
        <div className={'content'}>

            <div className={'container'}>
                <Card>
                    <Card.Body>
                        <Form onSubmit={onSubmit}>
                        {error && <Alert variant={"warning"}>Voer de logingegevens goed in!</Alert>}
                        <>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Gebruikersnaam</Form.Label>
                                <Form.Control type="gebruikersnaam" placeholder="Gebruikersnaam" name={'username'} />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Wachtwoord</Form.Label>
                                <Form.Control type="password" placeholder="Wachtwoord" name={'password'}/>
                            </Form.Group>

                            <Form.Group controlId="login">
                                <Button type={"submit"} className={'float-right'}>Login</Button>
                            </Form.Group>
                        </>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

        </div>
    )
}
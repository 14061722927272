import React, {useState} from "react";
import {useLogin} from "../../context/login.context";
import {Badge} from "react-bootstrap";


export default function RequestPlayedBadge(props) {
    const [request, setRequest] = useState(props.request);
    const {loggedIn} = useLogin();

    const togglePlayed = () => {
        fetch(`${process.env.REACT_APP_API_URL}/request/toggle/${request.id}`, {
                method: 'PUT'
            })
            .then(response => response.json())
            .then(data => setRequest(data));
    }

    if(loggedIn) {
        if(!!request.played) {
            return <Badge bg="primary" className={"cursor-pointer text-black badge-secondary"} onClick={togglePlayed}>Gedraaid</Badge>
        } else {
            return <Badge bg="secondary" className={"cursor-pointer text-black badge-secondary"} onClick={togglePlayed}>Nog even geduld</Badge>
        }
    }

    if(!!request.played) {
        return <Badge bg={"success text-black"}>Gedraaid</Badge>
    } else {
        return <Badge bg={"warning text-black"}>Nog even geduld</Badge>
    }
}
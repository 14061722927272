import {Button, Card, Form} from "react-bootstrap";
import React from "react";
import { useNavigate } from 'react-router-dom';

export default function RequestForm(props) {
    const navigate = useNavigate();

    const submitHandler = function(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = {
            name : formData.get('name'),
            request: formData.get('request'),
        }

        fetch(`${process.env.REACT_APP_API_URL}/request`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(() => navigate('/'));

        return false;
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Verzoekje aanvragen</Card.Title>

                <hr/>

                <Form name={'request'} onSubmit={submitHandler}>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Naam</Form.Label>
                        <Form.Control type="text" placeholder="Je naam invullen" name={'name'} />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Verzoekje</Form.Label>
                        <Form.Control type="text" placeholder="Verzoek nummer" name={'request'} />
                    </Form.Group>

                    <Button variant="primary" className="mt-2" type="submit">Insturen</Button>
                </Form>
            </Card.Body>
        </Card>
    );
}
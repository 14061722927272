import React, {useContext, useEffect, useMemo, useState} from "react";

// initialize context
const ConfigContext = React.createContext(false);

// create hook function
export const useConfig = () => {
    return useContext(ConfigContext);
};

//create loggedIn provider
export function ConfigProvider(Component) {
    function TheConfigProvider(props) {
        const [config, setConfig] = useState(null);

        useEffect(() => {
            fetch(`${process.env.REACT_APP_API_URL}/config`, {
                method: 'GET', // or 'PUT'
                headers: {'Accept': 'application/json',},
            })
            .then(r => r.json())
            .then(setConfig)

        }, []);

        const providerValue = useMemo(() => { return config }, [config]);

        return (
            <ConfigContext.Provider value={ providerValue }>
                {config == null ? <>Loading...</> :
                    <Component {...props} />
                }
            </ConfigContext.Provider>
        );
    }

    return TheConfigProvider;
}


// export context
export default ConfigContext;
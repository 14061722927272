import React from "react";
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useConfig} from "../../context/config.context";

export default function NavBar() {
    const config = useConfig();

    return (
        <Navbar className={'justify-content-between'}>
            <div>
                <Link to={'/'} ><img className="ms-3" alt={'Strooteam FM'} src={'/assets/img/STROOTEAM-borstlogo.png'}/></Link>
            </div>


            <div className={'text-center text-white me-3'}>
                <Link to={'/sponsors'} className="text-white">Sponsoren</Link>
                <span className="vr mx-3"></span>
                <h5 className="d-inline">App met de studio: {config.phoneNumber}</h5>
            </div>
        </Navbar>
    )
}
import React, {useEffect, useState} from "react";
import {Button, Card, Container, Form} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import Moment from "react-moment";
import { AdminHeader } from "./admin_header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function AdminSponsors() {
    const [sponsors, setSponsors] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async function fetchData() {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/sponsor`, {
            method: 'GET', // or 'PUT'
            headers: {'Accept': 'application/json',},
        })
            .then(response => response.json())
            .then(data => setSponsors(data))
            .finally(data => setLoading(false));
    }

    const handleSubmit = (e) => {
        e.preventDefault(); 
        const formData = new FormData(e.target);

        fetch(`${process.env.REACT_APP_API_URL}/sponsor`, {
            method: 'POST', // or 'PUT'T'
            body: formData
        }).then((r) => {
            window.location.reload();
        });
    }

    const removeSponsor = (id)=> {
        fetch(`${process.env.REACT_APP_API_URL}/sponsor/${id}`, {method: 'DELETE'})
            .then(response => response.json())
            .then(data => setSponsors(data))
    }


    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true
        },
        {
            name: 'Datum',
            selector: row => row.createdAt,
            cell: row => <Moment format={"YYYY-MM-DD HH:mm"}>{row.createdAt}</Moment>,
            sortable: true
        },
        {
            name: 'Naam',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Ondertitel',
            selector: row => row.subtitle,
            sortable: true
        },
        {
            name: 'Website',
            selector: row => row.website,
            sortable: true
        },
        {
            name: 'Logo',
            selector: row => row.hasLogo,
            sortable: true,
            cell: row => row.hasLogo ? <a href={`${process.env.REACT_APP_API_URL}/sponsor/${row.id}/logo`}><img alt={row.name} style={{maxHeight: '100px', maxWidth: '150px'}} src={`${process.env.REACT_APP_API_URL}/sponsor/${row.id}/logo`}/></a> : 'Geen logo'
        },
        {
            name: '',
            selector: row => row.hasLogo,
            sortable: true,
            width: '100px',
            cell: row => <FontAwesomeIcon icon={faTrash} className="text-danger" style={{cursor: "pointer"}} onClick={() => {
                if (window.confirm(`Weet je zeker dat je ${row.name} wilt verwijderen?`) === true) {  
                    removeSponsor(row.id)
                }
            }}/> 
        },
    ]

    return (
        <>
            <Container fluid>

                <AdminHeader title={'Sponsoren'}/>

                <Card className="mb-3">
                    <Card.Body>
                        <Form onSubmit={handleSubmit} >
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Sponsor</Form.Label>
                                <Form.Control type="text" placeholder="sponsor" name="name" />
                                <Form.Text className="text-muted">De naam van de sponsor.</Form.Text>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="subtitle">
                                <Form.Label>Ondertitel</Form.Label>
                                <Form.Control type="text" placeholder="Ondertitel" name="subtitle" />
                                <Form.Text className="text-muted">De naam van de sponsor.</Form.Text>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="website">
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="text" placeholder="sponsor" name="website" />
                                <Form.Text className="text-muted">De website van de sponsor.</Form.Text>
                            </Form.Group>
                            
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Logo</Form.Label>
                                <Form.Control type="file" name="logo" />
                            </Form.Group>

                            <Form.Group controlId="submit" className="mb-3">
                                <Button type="submit" variant="primary">Sponsor toevoegen</Button>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>

                <Card>
                    {sponsors == null ? loading : <DataTable
                        columns={columns}
                        data={sponsors}
                        defaultSortFieldId={1}
                        defaultSortAsc={false}
                        pagination
                        
                    />}
                </Card>
            </Container>
        </>
    )
}
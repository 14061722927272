import React from "react";
import { Nav } from "react-bootstrap";



export function AdminHeader({title}) {
    return (
	    <div className="admin-header d-flex justify-content-between">  
            <h1 className={'text-white'}>Admin {title}</h1>
            <Nav>
                <Nav.Link className="text-white" href="/admin/">Verzoekjes</Nav.Link>
                <Nav.Link className="text-white" href="/admin/sponsors">Sponsoren</Nav.Link>
            </Nav>
        </div>  
    )
}
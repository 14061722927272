import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

// initialize context
const LoginContext = React.createContext(false);

// create hook function
export const useLogin = () => {
    return useContext(LoginContext);
};

//create loggedIn provider
export function LoginProvider(Component) {
    function TheLoginProvider(props) {
        const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedin") ?? false);

        const loginStatus = (loggedIn) => {
            setLoggedIn(loggedIn);
            if(loggedIn) {
                localStorage.setItem("loggedin", "true");
            } else {
                localStorage.clear();
            }
        };

        const login = useCallback(() => {
            loginStatus(true)
        }, [])

        const logout = useCallback(() => {
            loginStatus(false)
        }, [])

        const providerValue = useMemo(() => { return { loggedIn ,login, logout } }, [loggedIn, login, logout]);

        return (
            <LoginContext.Provider value={ providerValue }>
                <Component {...props} />
            </LoginContext.Provider>
        );
    }

    return TheLoginProvider;
}


export function Secured({redirect = false, children}) {
    const {loggedIn} = useLogin();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loggedIn && redirect) {
            navigate('/login')
        }
    }, [loggedIn, redirect, navigate]);

    if (loggedIn) {
        return children;
    }
    return null
}
// export context
export default LoginContext;
import {Card, Table, Spinner, Button} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import RequestPlayedBadge from "./request.played.badge";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAlt} from "@fortawesome/free-solid-svg-icons";

export default function RequestList() {
    const [doRequest] = useState(false);
    const [requests, setRequests] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async function fetchData() {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/request`, {
            method: 'GET', // or 'PUT'
            headers: {'Accept': 'application/json',},
        })
        .then(response => response.json())
        .then(data => setRequests(data))
        .then(() => setLoading(false));
    }

    useEffect(() => {
        const interval = setInterval(() => fetchData(), 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const renderRequest = (request) => {
        return (
            <tr key={request.id}>
                <td>{request.name}</td>
                <td>{request.request}</td>
                <td>
                    <div className={'float-right'}>
                        <RequestPlayedBadge request={request}/>
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title className={'d-flex justify-content-between border-bottom p-2'}>
                        <div>
                            <span>Verzoekjes</span>
                            <span>{loading && <Spinner animation="border" role="status"/>}</span>
                        </div>
                        <div>
                            {!doRequest &&
                                <Link to={'/request'}>
                                    <Button size={'sm'} className={'cursor-pointer'} style={{backgroundColor: '#ce5900', borderColor: '#ce5900'}}>
                                        Aanvragen <FontAwesomeIcon className={'do-request-button'} icon={faCommentAlt}/>
                                    </Button>
                                </Link>
                            }
                        </div>
                </Card.Title>
                {requests == null &&
                    <div className={'texts-center'}>
                        <div className={'text-center p-5'}>
                            <Spinner animation="border" variant="primary"/>
                        </div>
                    </div>
                }
                <div style={{maxHeight: '250px', overflow: 'auto'}}>
                {requests != null &&
                    <Table>
                        <tbody>
                            {requests.map((request, index) => renderRequest(request, index))}
                        </tbody>
                    </Table>
                }
                </div>
                <Card.Text>

                </Card.Text>
            </Card.Body>
        </Card>
    );
}
import React, { useEffect, useState } from "react";
import {Card, Button, Row} from "react-bootstrap";


export default function Sponsors() {
    const [sponsors, setSponsors] = useState(null);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/sponsor`)
            .then(data => data.json())
            .then(data => shuffle(data))
            .then(setSponsors);
    }, []);

    if(sponsors == null) {
        return null;
    }

    const items = sponsors.map((sponsor, i) => {
        let logoUrl = `${process.env.REACT_APP_API_URL}/sponsor/${sponsor.id}/logo`;
        if (!sponsor.hasLogo) {
            logoUrl = `https://placehold.co/600x300?text=${sponsor.name}`;
        } 
        return <Card style={{ width: '16rem' }} className="me-2 pt-2" key={i}>
                <Card.Img variant="top" src={logoUrl} />
                <Card.Body className="align-content-end ">
                    <Card.Title>{sponsor.name}</Card.Title>
                    <Card.Text>{sponsor.subtitle}</Card.Text>
                    <Button disabled={sponsor.website.length === 0} style={{backgroundColor: '#ce5900', borderColor: '#ce5900'}} size="sm" href={sponsor.website}>Website</Button>
                </Card.Body>
        </Card>
    });


    return (
        <div className={'content pb-4'}>

            <div className={'container'}>
                <h1 className="text-white">Onze {sponsors.length} sponsoren</h1>
                <p className="text-white"><strong>Wil jij ons ook sponseren? Dat kan!! Neem contact met ons op via het mobiele nummer 06-42687725 of met 1 van onze Dj's</strong></p>
                
                <Row xs={1} md={2} className="g-4">
                    {items}
                </Row>
            </div>
        </div>
    )
}

function shuffle(array) {
    const newArray = [...array]
    const length = newArray.length

    for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random())
        const randomItem = newArray.splice(randomPosition, 1)

        newArray.push(...randomItem)
    }

    return newArray
}
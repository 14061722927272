import React, {useEffect, useState} from "react";
import {Card, Container} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import Moment from "react-moment";
import RequestPlayedBadge from "../components/request/request.played.badge";
import { AdminHeader } from "./admin_header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Admin() {
    const [requests, setRequests] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async function fetchData() {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/request`, {
            method: 'GET', // or 'PUT'
            headers: {'Accept': 'application/json',},
        })
            .then(response => response.json())
            .then(data => setRequests(data))
            .then(data => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const removeRequest = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/request/${id}`, {method: 'DELETE'})
            .then(response => response.json())
            .then(data => setRequests(data))
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Datum',
            selector: row => row.createdAt,
            cell: row => <Moment format={"YYYY-MM-DD HH:mm"}>{row.createdAt}</Moment>,
            sortable: true,
        },
        {
            name: 'Naam',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Verzoekje',
            selector: row => row.request,
            sortable: true
        },
        {
            name: 'Afgespeeld',
            selector: row => row.played,
            sortable: true,
            cell: row => <RequestPlayedBadge request={row} />,
        },
        {
            name: '',
            selector: row => row.hasLogo,
            sortable: true,
            width: '100px',
            cell: row => <FontAwesomeIcon icon={faTrash} className="text-danger" style={{cursor: "pointer"}} onClick={() => {
                if (window.confirm(`Weet je zeker dat je ${row.name} - ${row.request} wilt verwijderen?`) === true) {  
                    removeRequest(row.id)
                }
            }}/> 
        },
    ]

    return (
        <>
            <Container fluid>

                <AdminHeader title={'Verzoekjes'}/>

                <Card>
                    {requests == null ? loading : <DataTable
                        columns={columns}
                        data={requests}
                        defaultSortFieldId={1}
                        defaultSortAsc={false}
                        pagination
                    />}
                </Card>
            </Container>
        </>
    )
}